import React from 'react'
import { IconProps } from './icons.types'

export const Check: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ic-check">
      <path
        id="Stroke"
        d="M13.3327 4.57031L5.99935 11.9036L2.66602 8.57031"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
