import classnames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import styles from './logo-moises.module.scss'

export interface Props {
  className?: string
  small?: boolean
}

export const LogoMoises: React.FC<Props> = ({ className, small }) => {
  const { push } = useRouter()

  const isDevEnvironment = useMemo(() => {
    if (typeof window === 'undefined') return false
    return (
      process.env.NEXT_PUBLIC_LOCAL_DEV &&
      window?.location?.host === 'dev.moises.ai:3001'
    )
  }, [])

  const onGoLibrary = useCallback(() => {
    push(`${isDevEnvironment ? '/studio' : ''}/library`)
  }, [push, isDevEnvironment])

  return (
    <button
      type="button"
      onClick={onGoLibrary}
      className={classnames(className, styles.button, {
        [styles.small]: small
      })}
    >
      <picture>
        <img
          id="side_bar_moises_logo"
          alt="Moises"
          className={classnames(styles.image, { [styles.small]: small })}
          src={`//storage.googleapis.com/scala-web/assets/images/${
            small ? 'moises-icon-logo-white' : 'moises-primary-logo-white'
          }.svg`}
        />
      </picture>
    </button>
  )
}
