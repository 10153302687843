import React from 'react'
import { IconProps } from './icons.types'

export const AlertRound: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ic-alert-round">
      <path
        id="Fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11.5703C8.76142 11.5703 11 9.33174 11 6.57031C11 3.80889 8.76142 1.57031 6 1.57031C3.23858 1.57031 1 3.80889 1 6.57031C1 9.33174 3.23858 11.5703 6 11.5703ZM5.9375 7.88281C5.52329 7.88281 5.1875 8.2186 5.1875 8.63281C5.1875 9.04703 5.52329 9.38281 5.9375 9.38281C6.35171 9.38281 6.6875 9.04703 6.6875 8.63281C6.6875 8.2186 6.35171 7.88281 5.9375 7.88281ZM5.96875 3.44531C5.74438 3.44531 5.5625 3.6272 5.5625 3.85156V6.5C5.5625 6.72437 5.74438 6.90625 5.96875 6.90625C6.19312 6.90625 6.375 6.72437 6.375 6.5V3.85156C6.375 3.6272 6.19312 3.44531 5.96875 3.44531Z"
        fill="currentColor"
      />
    </g>
  </svg>
)
