import React from 'react'
import { IconProps } from './icons.types'

export const NoMusicNote: React.FC<IconProps> = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M11.3332 8.50009C11.3332 4.5001 7.55913 4.50011 7.55913 2.13477V8.00016M2.6665 3.16677L13.3332 13.8334M3.4598 12.7143V13.4866C3.4598 14.3614 4.28803 14.9992 5.1338 14.7757L6.56646 14.3971C7.15155 14.2425 7.55913 13.7182 7.55913 13.113V11.47C7.55913 11.0326 7.14502 10.7137 6.72213 10.8255L4.45247 11.4253C3.86738 11.5799 3.4598 12.1092 3.4598 12.7143Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
