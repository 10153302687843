import React from 'react'
import { IconProps } from './icons.types'

export const MusicNote: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.55929 10.1042V12.6129C7.55929 13.2181 7.15172 13.7424 6.56663 13.897L5.13396 14.2756C4.28819 14.4991 3.45996 13.8613 3.45996 12.9865V12.2142C3.45996 11.6091 3.86754 11.0798 4.45263 10.9252L7.55929 10.1042ZM7.55929 10.1042V1.63467C7.55929 4.00001 11.3333 4.00001 11.3333 8"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)
