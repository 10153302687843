import React from 'react'
import { IconProps } from './icons.types'

export const UploadError: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ic-upload-2-error">
      <path
        id="Stroke"
        d="M9.32809 14.6719L7.99997 16M12 17V21M19.5 19C19.5 19 21.8452 17.6832 22.5798 15.9986C23.0239 14.9804 23.1162 13.8432 22.8422 12.7667C22.5682 11.6902 21.9434 10.7355 21.0666 10.0535C20.1898 9.3714 19.1108 9.00075 18 9.00001H16.74C16.4373 7.82926 15.8731 6.74235 15.0899 5.82101C14.3067 4.89967 13.3248 4.16786 12.2181 3.68062C11.1113 3.19338 9.90851 2.96337 8.70008 3.0079C7.49164 3.05242 6 3.50003 5 4.50003M2.99996 16.3C2.1992 15.3939 1.61428 14.318 1.28917 13.1533C0.964065 11.9885 0.90723 10.7653 1.12294 9.57541C1.28765 8.66685 1.608 7.79584 2.06783 7.00003M2.99997 3L21 21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
