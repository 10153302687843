import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import classnames from 'classnames'
import React, {
  ReactElement,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import { useRouter } from 'next/router'
import { useEffectOnce, useWindowSize } from 'react-use'
import { useDebouncedCallback } from 'use-debounce'
import styles from './sidebar.module.scss'
import { LogoMoises } from '../../../components/sidebar/logo-moises'
import { Button } from '../../../components/sidebar/button'
import { NavUserError } from '../../../components/sidebar/nav-user-error'
import { NavUser } from '../nav-user'
import { useOnClickOutside } from '../../../hooks/misc/use-on-click-outside'
import { useCampaignCountdown } from '../../../hooks/campaign/use-campaing-countdown/use-campaign-countdown'
import { useAnnouncement } from '../../../hooks/announcements/use-announcement'
import { ContextShell } from '../../context'
import { Icon } from '../../../components/icon'
import { PlaylistsSidebar } from '../../playlist/playlists-sidebar'
import { Loading } from '../../../components/loading'
import { trigger } from '../../../lib/events'

interface Props {
  className?: string
  error?: boolean
  loading?: boolean
}

interface NavFilters {
  title: string
  id: string
  icon: string
  beta?: string
  badge?: boolean
  active: boolean
  onClick: () => void
}

interface CreateNavItemProps {
  title: string
  id: string
  icon: string
  activePaths: string[]
  featureName: string
  newPath?: string
  newPathExternal?: string
  defaultPath: string
  beta?: string | boolean
  badge?: boolean
  excludePaths?: string[]
}

export const Sidebar: React.FC<Props> = ({
  className,
  error,
  loading
}): ReactElement => {
  const ref: RefObject<any> = useRef()
  const [isHover, setIsHover] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const { i18n } = useLingui()
  const { width } = useWindowSize()
  const {
    user,
    sidebar: { isOpenedSidebar },
    campaign
  } = useContext(ContextShell)

  const { pathname, asPath, push } = useRouter()

  const campaignCountdown = useCampaignCountdown(
    campaign?.type === 'DAYONEDISCOUNT' ? 1000 : 1000 * 60
  )

  const isDevEnvironment = useMemo(() => {
    if (typeof window === 'undefined') return false
    return (
      process.env.NEXT_PUBLIC_LOCAL_DEV &&
      window?.location?.host === 'dev.moises.ai:3001'
    )
  }, [])

  const announcement = useAnnouncement()

  useOnClickOutside(ref, () => setIsHover(false))

  const isDenoiserEnabled = user?.featureFlags?.denoiser || false

  const isSmallScreen = useMemo(() => width < 950, [width])
  const isMobile = useMemo(() => width < 640, [width])

  const handleHoverEnterCallback = useCallback(() => {
    setIsHover(true)
  }, [])

  const handleHoverOutCallback = useCallback(() => {
    setIsHover(false)
  }, [])

  const handleHoverEnter = useDebouncedCallback(handleHoverEnterCallback, 100)
  const handleHoverOut = useDebouncedCallback(handleHoverOutCallback, 100)

  const isNewMastering = user?.featureFlags?.newMastering || false

  const selectedPages = useMemo(
    () =>
      isSmallScreen ||
      pathname.includes('/subscribe') ||
      pathname.includes('/pricing') ||
      pathname.includes('/voice') ||
      pathname.includes('/voice2') ||
      pathname.includes('/mastering') ||
      pathname.includes('/player') ||
      pathname.includes('/editor') ||
      pathname.includes('/lyric-writer') ||
      pathname.includes('/library/[taskId]'),
    [pathname, isSmallScreen]
  )

  const isCollapsed = useMemo(
    () => (!isOpenedSidebar || selectedPages) && !isHover,
    [isOpenedSidebar, isHover, selectedPages]
  )

  const isHovered = useMemo(
    () => isHover && (!isOpenedSidebar || selectedPages) && !isCollapsed,
    [isHover, isCollapsed, isOpenedSidebar, selectedPages]
  )

  const onGoLibrary = useCallback(
    () => push(`${isDevEnvironment ? '/studio' : ''}/library`),
    [push, isDevEnvironment]
  )

  const createNavItem = useCallback(
    ({
      title,
      id,
      icon,
      activePaths,
      excludePaths = [],
      featureName,
      newPath,
      newPathExternal,
      defaultPath,
      beta = false,
      badge = false
    }: CreateNavItemProps) => {
      const isExcluded = excludePaths.some((path) => asPath.includes(path))

      const isActive =
        !isExcluded && activePaths.some((path) => asPath.includes(path))

      return {
        title,
        id,
        icon,
        beta,
        active: isActive,
        onClick: () => {
          announcement.onClickNewFeature(featureName)

          if (newPathExternal) {
            if (newPathExternal !== pathname) {
              trigger('external:dispatch-event-unmount')
              window.location.href = newPathExternal
            }
          } else {
            push(newPath || defaultPath)
          }
        },
        badge: badge || announcement.showNewFeature(featureName)
      }
    },
    [announcement, asPath, pathname, push]
  )

  const items = useMemo<NavFilters[]>(
    () =>
      [
        createNavItem({
          title: i18n._(t`task.label.separate`),
          id: 'side_bar_track_separation',
          icon: 'spliter',
          activePaths: ['/library/', '/upload/split'],
          excludePaths: ['/library/?filter=denoiser'],
          featureName: 'library',
          defaultPath: isDevEnvironment ? '/studio/library' : '/library'
        }),
        createNavItem({
          title: 'Voice Studio',
          id: 'side_bar_voice_studio',
          icon: 'user-voice',
          activePaths: ['/voice', '/voice-studio'],
          featureName: 'voice',
          newPath: '/voice-studio',
          newPathExternal: '/voice-studio/',
          defaultPath: '/voice'
        }),
        createNavItem({
          title: i18n._(t`task.label.master`),
          id: 'side_bar_mastering',
          icon: 'mastering',
          activePaths: [
            '/library/?filter=master',
            '/upload/master',
            '/mastering'
          ],
          featureName: 'master',
          newPathExternal: isNewMastering ? '/mastering/' : undefined,
          defaultPath: isDevEnvironment
            ? '/studio/library/?filter=master'
            : '/library/?filter=master'
        }),
        isDenoiserEnabled &&
          createNavItem({
            title: i18n._(t`task.label.denoiser`),
            id: 'side_bar_denoiser',
            icon: 'denoiser',
            beta: i18n._(t`beta`),
            activePaths: ['/library/?filter=denoiser', '/upload/denoiser'],
            featureName: 'denoiser',
            defaultPath: isDevEnvironment
              ? '/studio/library/?filter=denoiser'
              : '/library/?filter=denoiser'
          }),
        createNavItem({
          title: i18n._(t`lyric_writer`),
          id: 'side_bar_lyric_writer',
          icon: 'songwriting',
          activePaths: ['/lyric-writer'],
          featureName: 'lyric-writer',
          defaultPath: isDevEnvironment
            ? '/studio/lyric-writer'
            : '/lyric-writer'
        }),
        createNavItem({
          title: 'Plugins',
          id: 'side_bar_plugins',
          icon: 'widget',
          activePaths: ['/plugins'],
          featureName: 'plugins',
          defaultPath: isDevEnvironment ? '/studio/plugins' : '/plugins'
        })
      ].filter(Boolean) as NavFilters[],
    [i18n, isDenoiserEnabled, isNewMastering, createNavItem, isDevEnvironment]
  )

  useEffectOnce(() => {
    setIsMounted(true)
  })

  if (!isMounted) {
    return <></>
  }

  return (
    <>
      <div
        className={classnames(className, styles.container, {
          [styles.collapsed]: isCollapsed
        })}
        onMouseEnter={handleHoverEnter}
        onMouseLeave={handleHoverOut}
        ref={ref}
      >
        <LogoMoises small={isCollapsed} className={styles.logo} />

        <div className={styles.content}>
          <div className={styles.nav}>
            {!user ? (
              <Loading type="skeleton-sidebar-menu" />
            ) : (
              items.map((props) => (
                <Button
                  id={props.id}
                  key={props.title}
                  badge={!isCollapsed && props?.badge}
                  small={isCollapsed}
                  disabled={loading}
                  title={props.title}
                  beta={props?.beta}
                  onClick={props.onClick}
                  active={props?.active && !loading}
                  icon={<Icon width={20} height={20} name={props.icon} />}
                  className={styles.itemButton}
                />
              ))
            )}
          </div>

          <div className={styles.setlists}>
            {!user?.id || isCollapsed ? null : <PlaylistsSidebar />}
            {isCollapsed && (
              <div className={styles.setlistCollapsed}>
                <Button
                  small={isCollapsed}
                  disabled={loading}
                  onClick={onGoLibrary}
                  className={styles.button}
                  icon={<Icon width={24} height={24} name="music-list" />}
                />
              </div>
            )}
          </div>
        </div>

        <div
          className={classnames(styles.user, {
            [styles.collapsed]: isCollapsed,
            [styles.extraHeight]:
              (!isCollapsed && campaignCountdown.show) ||
              (!isCollapsed && announcement.show)
          })}
        >
          {loading ? (
            <Loading className={styles.loading} type="skeleton-profile" />
          ) : error ? (
            <NavUserError
              small={isCollapsed}
              className={styles.loading}
              title={i18n._(t`task.list.state.failed`)}
            />
          ) : (
            <NavUser
              small={isCollapsed}
              bannerCountdown={campaignCountdown}
              announcement={announcement}
              showDaysInCountdown={campaign?.type !== 'DAYONEDISCOUNT'}
            />
          )}
        </div>
      </div>

      <div
        className={classnames(className, {
          [styles['safe-space']]: !isHovered && !isMobile,
          [styles['safe-space-collapsed']]:
            (isCollapsed || isHovered) && !isMobile
        })}
      />
    </>
  )
}
